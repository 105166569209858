import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Permis from "../../assets/images/permisos.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  createUser,
  resetPasswordConfirm,
  userInsertInToGroup,
} from "../../apis/apiUsers";
import { Col, Row, FormCheck } from "react-bootstrap";
import { MCButton } from "../MainComponents/Button/Button";
import { MCSelectSome } from "../MainComponents/SelectSome/SelectSome";
import { MCInput } from "../MainComponents/Input/Input";
import { GeneralIconHeader } from "../Title/GeneralHeader";
import { useTranslation } from "react-i18next";

import { getListGroupEAM, updateUserAdvanceOptions } from "../../apis/apiEAM";
import { assignRoleToUser, getListRoles } from "../../apis";

import { catalogByPart } from "../../apis";
import { MCSelect } from "../MainComponents/Select/Select";
import { SingleRowTextLoading } from "../Loading/SingleRowText/SingleRowText";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { Breadcrum } from "../MainComponents/Breadcrumb/Breadcrumb";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { startGettingUserInfo } from "../../store/auth/authThunks";

const showSnackBar = (msg, type = "error") => {
  enqueueSnackbar(`${msg}`, {
    variant: type,
    autoHideDuration: 3000,
  });
};

const AssignRole = ({ userId, currentRole }) => {
  const [rolesList, setRolesList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [t] = useTranslation("users");

  useEffect(() => {
    setIsLoading(true);
    getListRoles().then((resp) => {
      setRolesList(resp.response);
      if (currentRole !== null && Array.isArray(resp.response)) {
        const currentRoleToState = resp.response.find(
          (item) => item.id === currentRole
        );
        setSelectedRole(currentRoleToState);
      }
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    if (!selectedRole) {
      showSnackBar(t("User.must_select_role"));
      return;
    }
    assignRoleToUser({ user_id: userId, role_id: selectedRole.id }).then(
      (resp) => {
        showSnackBar(t("User.user_role_updated"), "success");
      }
    );
  };


  return (
    <>
      <h5 className="mb-20">{t("User.assign_user_role")}</h5>
      <div className="d-flex justify-content-beetween align-items-center">
        <div className="w-100">
          {!isLoading && Array.isArray(rolesList) && (
            <MCSelect
              optionLabel={"name"}
              optionValue="id"
              options={rolesList}
              value={selectedRole}
              onChange={setSelectedRole}
              className="w-100 me-1"
            />
          )}
        </div>
        <MCButton
          label={t("User.assign")}
          variant="primary"
          className="ms-2"
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export function validarContrasenia(contrasenia) {
  // Utilizamos una expresión regular para validar la contraseña
  const patron =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+|~\-=`{}\[\]:";'<>,.?/\\]).{8,}$/;
  patron.test(contrasenia);
  return patron.test(contrasenia);
}

export const returnStringOfSelections = async (
  string,
  catalogue,
  is_own,
  value,
  nestNum = 0,
  forEdition = true
) => {
  let newString = string;
  const splitedAnsr = value.split("::");
  const slicedAnsr = splitedAnsr.slice(0, 2 * nestNum);
  const joined = slicedAnsr.join("::");
  const slicedAnsrKey = splitedAnsr.slice(nestNum * 2, 2 * (nestNum + 1));
  const selected =
    slicedAnsrKey.length === 2 ? slicedAnsrKey[slicedAnsrKey.length - 1] : "";
  const resp = await catalogByPart({
    is_own,
    catalogue,
    path: joined ? joined : "/",
    ...(forEdition && { show_all: true }),
  });
  if (resp.error) return "error";
  if (selected) {
    const newResp = resp.data.find((item) => item.key == selected);
    newString = newString
      ? `${newString} > ${newResp?.label}`
      : `${newResp?.label}`;
    return returnStringOfSelections(
      newString,
      catalogue,
      is_own,
      value,
      nestNum + 1,
      forEdition
    );
  } else {
    return string;
  }
};

const AddSegment = ({
  segmentList,
  currentSegments = [],
  setGroupSegment,
  userId,
}) => {
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const [t] = useTranslation("users");

  const handleAddSegment = async (id) => {
    try {
      await userInsertInToGroup({
        user_id: userId,
        group_list: [...currentSegments, selectedSegment.id],
      });
      setGroupSegment([...currentSegments, selectedSegment.id]);
      setSelectedSegment(null);
      setIsActive(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelAdd = () => {
    setSelectedSegment(null);
    setIsActive(false);
  };

  if (!isActive) {
    return (
      <div className="d-flex justify-content-between">
        <h5>{t("User.add_access_filter_group")}</h5>
        <MCButton
          label="+"
          variant="primary"
          onClick={() => setIsActive(true)}
        />
      </div>
    );
  }

  return (
    <>
      <h5 className="mb-20">{t("User.add_access_filter_group")}</h5>
      <div>
        <div className="d-flex">
          <MCSelect
            optionLabel="name"
            options={segmentList?.filter(
              (segm) => !currentSegments.includes(segm.id)
            )}
            value={selectedSegment}
            onChange={setSelectedSegment}
            className="w-100 me-1"
          />
          <div className="d-flex">
            {selectedSegment && (
              <MCButton
                label={t("User.add")}
                variant="primary"
                className="me-1"
                onClick={handleAddSegment}
              />
            )}
            <MCButton
              label={t("User.cancel")}
              variant="danger"
              onClick={handleCancelAdd}
            />
          </div>
        </div>
        {selectedSegment && (
          <div className="d-flex flex-column">
            <h5 className="text-center my-3">{t("User.access_preview")}</h5>
            <Segment segment={selectedSegment.segment} />
          </div>
        )}
      </div>
    </>
  );
};

const Segment = ({ segment, nestLvl = 0 }) => {
  const [recursiveSegments, setRecursiveSegments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    mount();
  }, [segment]);

  const mount = async () => {
    setIsLoading(true);
    if (nestLvl === 0) {
      const tempScopeSeg = Object.entries(segment);
      const newSegments = tempScopeSeg.map(async (item) => {
        const splitedKey = item[0].split("::");
        const is_own = splitedKey[1] === "OWN" ? true : false;
        const catalogue = splitedKey[2];
        const value = [];
        if (Array.isArray(item[1].value)) {
          for (const val of item[1].value) {
            const valTemp = await returnStringOfSelections(
              "",
              catalogue,
              is_own,
              val
            );
            value.push(valTemp);
          }
        }
        return { label: item[1].label, child: item[1].child, value };
      });
      const ultimateSegments = await Promise.all(newSegments);
      setRecursiveSegments(ultimateSegments);
      setIsLoading(false);
    } else {
      const newSegments = segment.map(async (item) => {
        const splitedKey = item.field.split("::");
        const is_own = splitedKey[1] === "OWN" ? true : false;
        const catalogue = splitedKey[2];
        const value = [];
        if (Array.isArray(item.value)) {
          for (const val of item.value) {
            const valTemp = await returnStringOfSelections(
              "",
              catalogue,
              is_own,
              val
            );
            value.push(valTemp);
          }
        }
        return { label: item.label, child: item.child, value };
      });
      const ultimateSegments = await Promise.all(newSegments);
      setRecursiveSegments(ultimateSegments);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <SingleRowTextLoading className="my-1" />
        <SingleRowTextLoading className="my-1" />
        <SingleRowTextLoading className="my-1" />
      </>
    );
  }

  return (
    <>
      {recursiveSegments.map((item, idx) => (
        <div key={idx} className={`${nestLvl > 0 ? "ms-3" : ""}`}>
          <h6 className="mb-0">{item.label}</h6>
          <div className="mb-2">
            {Array.isArray(item.value) &&
              item.value.map((item2, idx2) => (
                <small key={idx2}>
                  <small>{item2}</small>
                </small>
              ))}
          </div>
          {Array.isArray(item.child) && (
            <Segment segment={item.child} nestLvl={nestLvl + 1} />
          )}
        </div>
      ))}
    </>
  );
};

const SecurityInformationSection = ({
  email,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isExistingUser,
}) => {
  const [t] = useTranslation("users");
  const { enqueueSnackbar } = useSnackbar();

  const [isActiveForgotPassword, setIsActiveForgotPassword] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");

  const handleSendCode = async () => {

    setIsActiveForgotPassword(true);

    // try {
    //   //const resp = await sendCodeResetPassword(email);
    //   setIsActiveForgotPassword(true);
    //   enqueueSnackbar(t("User.code_sent_email"), {
    //     variant: "success",
    //   });
    // } catch (error) {
    //   enqueueSnackbar(t("User.error_trying_send_confirmation_code"), {
    //     variant: "error",
    //   });
    // }

  };

  const handleResetPassword = async () => {
    if (password.trim() === "" || confirmPassword.trim() === "") {
      enqueueSnackbar(t("User.password_must_not_empty"), {
        variant: "error",
      });
      return;
    }
    if (password !== confirmPassword) {
      enqueueSnackbar(t("User.password_no_match"), {
        variant: "error",
      });
      return;
    }
    if (!validarContrasenia(password)) {
      enqueueSnackbar(t("User.password_must_contain"), {
        variant: "error",
      });
      return;
    }
    // if (confirmationCode.trim() === "") {
    //   enqueueSnackbar(t("User.invalid_confirmation_code"), {
    //     variant: "error",
    //   });
    //   return;
    // }
    try {
      const resp = await resetPasswordConfirm(
        email,
        password,
        confirmPassword
      );
      enqueueSnackbar(t("User.password_changed"), {
        variant: "success",
      });
      setIsActiveForgotPassword(false);
    } catch (error) {
      enqueueSnackbar(t("User.error_trying_reset_password"), {
        variant: "error",
      });
    }
  };

  const handleCancelResetPassword = () => {
    setIsActiveForgotPassword(false);
    setConfirmationCode("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <section className="dyTheme1 dyBorder1 rounded mt-4 p-4">
      <header className="d-flex justify-content-between">
        <h5 className="mb-20">{t("User.security_information")}</h5>
        {isExistingUser && !isActiveForgotPassword && (
          <em className="forgot-password-text" onClick={handleSendCode}>
            {t("User.forgot_password")}
          </em>
        )}
      </header>

      {(!isExistingUser || isActiveForgotPassword) && (
        <form>
          <Row>
            <Col md="6" className="mb-2">
              <label className="mb-2">{t("User.password")}:</label>
              <MCInput
                type="password"
                placeholder={t("User.password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
            <Col md="6" className="mb-2">
              <label className="mb-2">{t("User.confirm_password")}:</label>
              <MCInput
                type="password"
                placeholder={t("User.confirm_password")}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Col>
          </Row>
          {isActiveForgotPassword && (
            <>
              <Row>
                <Col className="d-flex justify-content-end">
                  <MCButton
                    label={t("User.change_password")}
                    className="me-2"
                    outline
                    variant="primary"
                    onClick={handleResetPassword}
                  />
                  <MCButton
                    label={t("User.cancel")}
                    outline
                    variant="danger"
                    onClick={handleCancelResetPassword}
                  />
                </Col>
              </Row>
            </>
          )}
        </form>
      )}
    </section>
  );
};

export const User = () => {

  const dispatch = useDispatch();
  const [t] = useTranslation("users");
  const { enqueueSnackbar } = useSnackbar();

  const [selectedSegment, setSelectedSegment] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const updateUserOnStore = () => {
    dispatch(startGettingUserInfo());
  }

  // => Component that returns a list of errors when the request failed (shows in modal)
  const Errors = ({ errors }) => {
    const getInput = (key) => {
      if (key === "email") return "Email";
      if (key === "password") return t("User.password");
      return "General";
    };

    return (
      <>
        {Array.isArray(errors) &&
          errors.map((item) => (
            <h4>
              {getInput(item[0])}: {item[1]}
            </h4>
          ))}
        {typeof errors === "string" && <h4>{errors}</h4>}
      </>
    );
  };

  // => Runs when press AddUser button
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.trim() === "" || confirmPassword.trim() === "") {
      enqueueSnackbar(t("User.password_must_not_empty"), {
        variant: "error",
      });
      return;
    }
    if (password !== confirmPassword) {
      enqueueSnackbar(t("User.password_no_match"), { variant: "error" });
      return;
    }
    if (!validarContrasenia(password)) {
      enqueueSnackbar(t("User.password_must_contain"), { variant: "error" });
      return;
    }
    try {
      const data = await createUser({
        email,
        password,
        first_name,
        last_name,
        username,
        phone_number,
      });
      if (!data.error) {
        MySwal.fire({
          title: t("User.user_successfully_created"),
          icon: "success",
          buttonsStyling: true,
          confirmButtonText: t("User.continue"),
          showConfirmButton: true,
          width: "50%",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          stopKeydownPropagation: false,
          confirmButtonColor: "#009ED7",
          customClass: {
            icon: "icon-sweet",
            confirmButton: "btn-sweet",
          },
        });
        navigate("/users");
      } else {
        MySwal.fire({
          icon: "warning",
          confirmButtonText: t("User.close"),
          showConfirmButton: true,
          width: "50%",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          stopKeydownPropagation: false,
          confirmButtonColor: "#009ED7",
          html: (
            <Errors
              errors={
                typeof data.message === "object"
                  ? Object.entries(data.message)
                  : data.message
              }
            />
          ),
          customClass: {
            confirmButton: "btn-sweet",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const MySwal = withReactContent(Swal);
  //-- Value of every input --//
  const [userv, setUserv] = useState("");
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [username, setUsername] = useState("");
  const [policy, setPolicy] = useState([]);
  const [phone_number, setPhone_number] = useState("");
  const [groupSegment, setGroupSegment] = useState([]);
  const [role, setRole] = useState(null);
  //-- Variable for control, if this component is used to edit a user, this variable get true value, otherwise it stay false --//
  const [isExistingUser, setIsExistingUser] = useState(false);
  //-- Existing segment groups --//
  const [listSegments, setListSegments] = useState([]);

  const [locked, setLocked] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const listStatusDict = {
    new: {
      label: t("status.new"),
      value: "new",
    },
    pending: {
      label: t("status.attention_pending"),
      value: "pending",
    },
    process: {
      label: t("status.under_investigation"),
      value: "process",
    },
    finish: {
      label: t("status.in_resolution"),
      value: "finish",
    },
    closed: {
      label: t("status.closed"),
      value: "closed",
    },
    draft: {
      label: t("status.draft"),
      value: "draft",
    },
  };

  const listStatus = [
    {
      label: t("status.new"),
      value: "new",
    },
    {
      label: t("status.attention_pending"),
      value: "pending",
    },
    {
      label: t("status.under_investigation"),
      value: "process",
    },
    {
      label: t("status.in_resolution"),
      value: "finish",
    },
    {
      label: t("status.closed"),
      value: "closed",
    },
    {
      label: t("status.draft"),
      value: "draft",
    },
  ];

  // Set variables if is an existing user
  useEffect(() => {
    if (state?.user) {
      setUserv(state.user);
      setUserId(state.user.id);
      setEmail(state.user.email);
      setFirst_name(state.user.first_name);
      setLast_name(state.user.last_name);
      setUsername(state.user.username);
      setPhone_number(state.user.phone_number);
      setGroupSegment(state.user.group || []);

      if (Object.keys(state.user.policy).length === 0) {
        setPolicy({
          deny: {
            status: [],
            locked: false,
            severity: false,
          },
        });
      } else {
        setPolicy(state.user.policy || []);
      }

      setRole(state.user.role);
      setIsExistingUser(true);

      // console.log("POLICY STATUS BY DEFAULT")
      // console.log(state.user.policy.deny?.status);

      setLocked(state.user.policy.deny?.locked || false);
      setSeverity(state.user.policy.deny?.severity || false);

      if (state.user.policy.deny?.status.length >= 1) {
        var list_status = [];
        state.user.policy.deny?.status.forEach((item) => {
          if (item !== null || item !== undefined) {
            list_status.push(listStatusDict[item]);
          }
        });
        setSelectedStatus(list_status);
      } else {
        setSelectedStatus(state.user.policy.deny?.status || []);
      }
    }
  }, []);

  const handleSecureChecks = async (e) => {
    const secureToChange = e.target.id !== "locked" ? "severity" : "locked";
    const value = e.target.checked;
    const rewrite_policy = policy;

    if (secureToChange == "severity") {
      rewrite_policy.deny.severity = value;
      setSeverity(value);
    }

    if (secureToChange == "locked") {
      rewrite_policy.deny.locked = value;
      setLocked(value);
    }

    setPolicy(rewrite_policy);
  };

  const selectStatus = (value) => {
    const rewrite_policy = policy;

    var status_list = [];

    value.forEach((item) => {
      if (item !== null || item !== undefined) {
        status_list.push(item);
      }
    });

    const cleanedList = status_list.filter(
      (item) => item !== null && item !== undefined
    );
    rewrite_policy.deny.status = cleanedList;
    setPolicy(rewrite_policy);
  };

  const saveSetting = async () => {
    var rewrite_policy = policy;

    if (policy?.deny.status.length >= 1) {
      rewrite_policy.deny.status = policy.deny.status.map((item) => item.value);
    }

    const resp = await updateUserAdvanceOptions({
      user_id: state.user.id,
      policy: policy ? rewrite_policy : null,
    });

    updateUserOnStore();

    if (!resp?.error) {
      navigate(-1);
    }
  };

  // Get list of segments groups
  useEffect(() => {
    getListGroupEAM().then((resp) => {
      setListSegments(resp.response);
    });
  }, []);

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);

    // Replace special characters with '_'
    // Assuming special characters are anything other than letters, numbers, and underscores
    const transformedUsername = newEmail.replace(/[^a-zA-Z0-9]/g, "_");
    setUsername(transformedUsername);
  };

  const getSegmentById = (id) => {
    if (listSegments.length > 0) {
      const segmentToAdd = listSegments.find((segment) => segment.id === id);
      return segmentToAdd?.segment || {};
    }
    return {};
  };

  const getSegmentLabelById = (id) => {
    if (listSegments.length > 0) {
      const segmentToAdd = listSegments.find((segment) => segment.id === id);
      return segmentToAdd?.name || "";
    }
    return "";
  };

  const handleDeleteSegment = async (id) => {
    const newGroupSegments = groupSegment.filter((item) => item !== id);
    try {
      await userInsertInToGroup({
        user_id: userId,
        group_list: newGroupSegments,
      });
      setGroupSegment(newGroupSegments);
    } catch (error) {
      console.log(error);
    }
  };

  const breadcrumbItems = [
    { label: t("User.home"), route: "/" },
    { label: t("User.users"), route: "/users" },
    { label: `${isExistingUser ? t("User.edit_user") : t("User.add_user")}` },
  ];

  return (
    <>
      <div id="settings" className="user-page">
        {isExistingUser ? (
          <GeneralIconHeader
            title={t("User.edit_user")}
            text={t("User.configure_user_roles_permissions")}
            icon="outline_user_edit"
          />
        ) : (
          <GeneralIconHeader
            title={t("User.add_user")}
            text={t("User.configure_user_roles_permissions")}
            icon="outline_user_add"
          />
        )}
        <Breadcrum items={breadcrumbItems} />
        <div className="d-block d-lg-flex mt-3">
          <div className="profile-first-block">
            <main className="dyTheme1 dyBorder1 rounded p-4">
              <h5 className="mb-20">{t("User.personal_information")}</h5>

              <Row>
                <Col md="6">
                  <div className="form-group mb-30 w-100 mr-5">
                    <label className="mb-2">{t("User.name")}: *</label>
                    <MCInput
                      type="text"
                      placeholder={t("User.name")}
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                      disabled={isExistingUser}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group mb-30 w-100">
                    <label className="mb-2">{t("User.lastname")}: *</label>
                    <MCInput
                      type="text"
                      placeholder={t("User.lastname")}
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                      disabled={isExistingUser}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="form-group mb-30 w-100 mr-5">
                    <label className="mb-2">Email:</label>
                    <MCInput
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => handleEmailChange(e.target.value)}
                      disabled={isExistingUser}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group mb-30 w-100">
                    <label className="mb-2">{t("User.username")}:</label>
                    <MCInput
                      type="text"
                      placeholder={t("User.username")}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      disabled={isExistingUser}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="form-group mb-30 w-100">
                    <label className="mb-2">{t("User.phone")}:</label>
                    <MCInput
                      type="text"
                      placeholder={t("User.phone")}
                      value={phone_number}
                      onChange={(e) => setPhone_number(e.target.value)}
                      disabled={isExistingUser}
                    />
                  </div>
                </Col>
              </Row>
            </main>

            {/* Security information section */}
            <SecurityInformationSection
              email={email}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              password={password}
              setPassword={setPassword}
              isExistingUser={isExistingUser}
            />

            {/* Add permissions section */}
            {isExistingUser && (
              <>
                <section className="dyTheme1 dyBorder1 rounded mt-4 p-4">
                  <AddSegment
                    userId={userId}
                    segmentList={listSegments}
                    currentSegments={groupSegment}
                    setGroupSegment={setGroupSegment}
                  />
                </section>

                <section className="dyTheme1 dyBorder1 rounded mt-4 p-4">
                  <AssignRole userId={userId} currentRole={role} />
                </section>

                <section className="dyTheme1 dyBorder1 rounded mt-4 p-4">
                  <h5>{t("User.advanced_options")}</h5>

                  <Row className="mt-4">
                    <Col md="9">
                      <div className="form-group mb-30 w-100 mr-5">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <small>{t("User.can_see_locked")}</small>
                          <FormCheck
                            id="locked"
                            type="switch"
                            checked={locked}
                            onChange={handleSecureChecks}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="9">
                      <div className="form-group mb-30 w-100 mr-5">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <small>{t("User.can_see_high_severity")}</small>
                          <FormCheck
                            id="severity"
                            type="switch"
                            checked={severity}
                            onChange={handleSecureChecks}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <div className="form-group mb-30 w-100 mr-5">
                        <div className="mt-3 mr-20 ">
                          <MCSelectSome
                            helptip={t("User.select_status_cant_see")}
                            placeholder={t("User.select_status_block")}
                            label={`${t("User.lock_status")}:`}
                            options={listStatus}
                            selecteds={selectedStatus}
                            onChange={(value) => selectStatus(value)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <MCButton
                    label={t("User.save_configuration")}
                    variant="primary"
                    onClick={() => saveSetting()}
                  />

                  {/* <pre>{JSON.stringify(policy, null, 2)}</pre>  */}
                </section>
              </>
            )}

            {!isExistingUser && (
              <section className="user-button mt-4">
                <Row>
                  <Col className="d-flex justify-content-end">
                    <MCButton
                      className="mt-0"
                      label={t("User.add_user")}
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                    />
                  </Col>
                </Row>
              </section>
            )}
          </div>

          {isExistingUser && (
            <div className="dyTheme1 dyBorder1 rounded profile-second-block p-20 user-second-bar">
              <h5 className="mb-20 text-left">
                {t("User.report_acces_filter")}
              </h5>
              {isExistingUser ? (
                <small className="pb-3 border-bottom">
                  {t("User.assign_permissions_user")}
                </small>
              ) : (
                <small className="pb-3 border-bottom">
                  {t("User.enter_permissions_user")}
                </small>
              )}
              <div className="d-flex flex-column p-20">
                <img
                  src={Permis}
                  alt="permission"
                  className="d-block mx-auto"
                />
              </div>

              <aside>
                {isExistingUser && (
                  <>
                    <div className="segment-user">
                      <h5>{t("User.user_segment")}</h5>

                      {/* <MCButton  
                          label={t("user.add_filter_user")}
                          variant="primary"
                          outline
                          onClick={() =>
                          navigate(`/segments/new/`, { state: { userv } })
                        } /> */}

                      {state?.user?.segment && (
                        <Segment segment={state.user.segment} />
                      )}
                    </div>

                    <hr />

                    <div className="segment-user">
                      <h5>{t("User.group_segment")}</h5>
                      {groupSegment.map((groupId) => {
                        return (
                          <div key={groupId}>
                            <h6>
                              --{getSegmentLabelById(groupId)}--
                              <span
                                onClick={() => handleDeleteSegment(groupId)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                x
                              </span>
                            </h6>
                            <Segment segment={getSegmentById(groupId)} />
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </aside>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
