import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { components } from "react-select";
import { Col, Dropdown, Modal } from "react-bootstrap";
import {
  catalogByPart,
  insertCatalogueChildren,
  updateStatusCatalogue,
} from "../../../apis";
import Icon from "../../Icon/Icon";
import { MCButton } from "../../MainComponents/Button/Button";
import { MCSelect } from "../../MainComponents/Select/Select";
import { MCInput, MCLabel } from "../../MainComponents";
import {
  hdlSchm,
  hdlChg,
  ReturnErrorMesages,
  hdlConstraints,
} from "./PreviewForm";
import {
  INPUT_TYPE_CATALOGUE_RADIO,
  INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION,
  INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL,
  INPUT_TYPE_CATALOGUE_SELECT,
  INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL,
  especialCatalogues,
  isForLanding,
} from "../consts";
import { ModalLocales } from "../../Catalogues/ModalLocales";
import { ModalEditInfoCatalogue } from "../../Catalogues/ModalEditInfoCatalogue";
import { useSnackbar } from "notistack";
import I18nText from "./i18n";
import queryString from "query-string";
import { useLocation } from "react-router";

const getConstraintValues = (constraints, schema) => {
  // Obtener las un array con los valores que estan activos en las constraints para que se eliminen las selecciones de los catalogos correspondientes
  const filtredConstraints = constraints.filter(
    (item) => item.catKey == schema.key && item.selected === true
  ); // Obtener las constraints que esten activas y que sean de este catalogo
  const valuesConstraints = filtredConstraints
    .map((item) => item.constraints)
    .flat(Infinity); // obtener los array de los valores de constraint activos y ponerlos en un array con un solo nivel
  return valuesConstraints; // retornar el array con los valores activos
};

const ModalExamples = ({ itemExamples, setShowExampleModal, styleDark }) => {

  const lang = isForLanding ? localStorage.getItem("selectedLanguage") : localStorage.getItem("lang") ?? "es";
  const i18n = {
    "catalogues_these_are_examples": I18nText({ lang, translation: "catalogues_these_are_examples" }),
  }

  const { gTheme } = useSelector((state) => state.theme);
  return (
    <Modal
      show
      onHide={() => setShowExampleModal(false)}
      backdrop="static"
      keyboard={false}
      centered
      // contentClassName={`modal-example-dialog ${styleDark}`}
      className={`${gTheme !== "light" ? "dark-mode" : ""}`}
    >
      <Modal.Header
        closeButton
        className={styleDark === "dark-mode" ? "btn-close-white" : ""}
      >
        <Modal.Title
          bsPrefix={
            styleDark === "dark-mode"
              ? "modal-title cus-modal-title"
              : "modal-title"
          }
        >
          {i18n["catalogues_these_are_examples"]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {itemExamples.map((itemTxt) => (
          <Col lg="12" key={itemTxt}>
            <p>{itemTxt}</p>
          </Col>
        ))}
      </Modal.Body>
    </Modal>
  );
};

const CatalogueRadioInput = ({
  scope,
  pathData,
  type,
  nestNum,
  returnValidClass,
  showErrorLastChild,
  GetDescritionAndExamples,
  handleKeyChange,
  selectedKey,
  isSelected,
  orderChildren,
  styleDark,
}) => {
  if (
    type === INPUT_TYPE_CATALOGUE_RADIO ||
    type === INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL
  ) {
    return (
      <div className="container-checkbox2">
        {scope.children &&
          orderChildren(scope.children).map((item, idxx) => {
            if (item.is_active) {
              return (
                <div
                  role="button"
                  className={`py-0 px-3 m-2 ${styleDark} ${isSelected(
                    selectedKey === item.key
                  )}`}
                  key={idxx}
                  onClick={(e) =>
                    selectedKey !== item.key
                      ? handleKeyChange(item.key, "click")
                      : () => {}
                  }
                >
                  <div
                    key={item.key}
                    className="form-check"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      className={`form-check-input  ${
                        showErrorLastChild ? returnValidClass() : ""
                      }`}
                      id={`${pathData}-${nestNum}-${idxx}`}
                      type="radio"
                      value={item.key}
                      name={item.path}
                      checked={selectedKey == item.key}
                      onChange={(e) =>
                        handleKeyChange(e.target.value, "change")
                      }
                    />
                    <label
                      role="button"
                      className={`form-check-label ${styleDark} ${isSelected(
                        selectedKey === item.key
                      )}`}
                      htmlFor={`${pathData}-${nestNum}-${idxx}`}
                    >
                      {item.label}
                    </label>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
      </div>
    );
  } else {
    return (
      <>
        {scope.children &&
          orderChildren(scope.children).map((item, idxx) => {
            if (item.is_active) {
              return (
                <div
                  role="button"
                  className={`container-checkbox p-3 m-2 ${styleDark} ${isSelected(
                    selectedKey === item.key
                  )}`}
                  key={idxx}
                  onClick={(e) =>
                    selectedKey !== item.key
                      ? handleKeyChange(item.key)
                      : () => {}
                  }
                >
                  <div
                    key={item.key}
                    className="form-check d-inline-block"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      className={`form-check-input  ${
                        showErrorLastChild ? returnValidClass() : ""
                      }`}
                      id={`${pathData}-${nestNum}-${idxx}`}
                      type="radio"
                      value={item.key}
                      name={item.path}
                      checked={selectedKey == item.key}
                      onChange={(e) => handleKeyChange(e.target.value)}
                    />
                    <label
                      role="button"
                      className={`form-check-label mb-1 ${styleDark} ${isSelected(
                        selectedKey === item.key
                      )}`}
                      htmlFor={`${pathData}-${nestNum}-${idxx}`}
                    >
                      {item.label}
                    </label>
                    {(type === INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION ||
                      type ===
                        INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL) && (
                      <GetDescritionAndExamples item={item} />
                    )}
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
      </>
    );
  }
};

export const ModalAddChild = ({
  path,
  catalogue,
  is_own,
  onHide,
  rerenderParent,
}) => {

  const lang = isForLanding ? localStorage.getItem("selectedLanguage") : localStorage.getItem("lang") ?? "es";
  const i18n = {
    "catalogues_edition_name_alredy_user": I18nText({ lang, translation: "catalogues_edition_name_alredy_user" }),
    "catalogues_edition_cannot_be_similar_labels": I18nText({ lang, translation: "catalogues_edition_cannot_be_similar_labels" }),
    "catalogues_edition_element_added_successfully": I18nText({ lang, translation: "catalogues_edition_element_added_successfully" }),
    "catalogues_edition_modal_add_child_title": I18nText({ lang, translation: "catalogues_edition_modal_add_child_title" }),
    "catalogues_edition_save": I18nText({ lang, translation: "catalogues_edition_save" }),
    "catalogues_edition_cancel": I18nText({ lang, translation: "catalogues_edition_cancel" })
  }

  const { enqueueSnackbar } = useSnackbar();

  const isValid = () => {
    if (childToAdd.trim() === "") {
      return i18n["catalogues_edition_languages"];
    }
    return true;
  };

  const handleAddChild = async () => {
    setTryToSend(true);
    const valid = isValid();
    if (valid !== true) {
      setError(valid);
      return;
    }
    const childToSave = {
      alias: catalogue,
      path,
      is_own,
      element: {
        label: childToAdd,
      },
    };
    const resp = await insertCatalogueChildren(childToSave);
    if (resp.error) {
      if (resp.payload === "element already exists") {
        enqueueSnackbar(i18n["catalogues_edition_cannot_be_similar_labels"], {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      }
    } else {
      enqueueSnackbar(i18n["catalogues_edition_element_added_successfully"], {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      rerenderParent();
    }
  };

  const { gTheme } = useSelector((state) => state.theme);
  const [childToAdd, setChildToAdd] = useState("");
  const [tryToSend, setTryToSend] = useState(false);
  const [error, setError] = useState("");

  return (
    <Modal
      show={true}
      onHide={onHide}
      className={`${gTheme !== "light" ? "dark-mode" : ""}`}
    >
      <Modal.Header
        closeButton
        className={`${gTheme !== "light" ? "btn-close-white" : ""} border-0`}
      />
      <Modal.Body>
        <h4>{i18n["catalogues_edition_modal_add_child_title"]}</h4>
        <MCInput
          value={childToAdd}
          onChange={(e) => setChildToAdd(e.target.value)}
          isValid={!(tryToSend && error)}
        />
        {tryToSend && error && <small className="invalid-msg">{error}</small>}
        <div className="mt-2 w-100">
          <MCButton
            label={i18n["catalogues_edition_cancel"]}
            variant="danger"
            className="me-2"
            outline
            onClick={onHide}
          />
          <MCButton
            label={i18n["catalogues_edition_save"]}
            variant="primary"
            outline
            onClick={handleAddChild}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

// Render de un input asociado a un catalogo
export const CatalogueInput = ({
  schema,
  scope,
  value,
  valid,
  origin,
  pathSchema,
  pathData,
  entireSchema,
  entireFormData,
  setSchemaState,
  setFormData,
  constraints = [],
  setConstraints = () => {},
  tryToNext,
  handleValidate,
  returnValidClass,
  rerenderParent,
  containerName,
  path = "",
  labelShowed = false,
  nestNum = 0,
  forEdition = false,
}) => {
  
  const lang = isForLanding ? localStorage.getItem("selectedLanguage") : localStorage.getItem("lang") ?? "es";
  const i18n = {
    "catalogues_examples_title": I18nText({ lang, translation: "catalogues_examples_title" }),
    "catalogues_placeholder": I18nText({ lang, translation: "catalogues_placeholder" }),
    "catalogues_inactive": I18nText({ lang, translation: "catalogues_inactive" }),
    "catalogues_placeholder": I18nText({ lang, translation: "catalogues_placeholder" }),
    "catalogues_edition_configuration": I18nText({ lang, translation: "catalogues_edition_configuration" }),
    "catalogues_edition_languages": I18nText({ lang, translation: "catalogues_edition_languages" }),
    "catalogues_edition_add_child": I18nText({ lang, translation: "catalogues_edition_add_child" }),
    "catalogues_edition_disable": I18nText({ lang, translation: "catalogues_edition_disable" }),
    "catalogues_edition_enable": I18nText({ lang, translation: "catalogues_edition_enable" }),
  }
  const { gTheme } = useSelector((state) => state.theme);

  //* QUERYS
  const location = useLocation();
  const qs = queryString;
  const search = qs.parse(location.search);

  //* PATH de el valor del catalogo
  const pathTemp = value ? value : "";
  const splitedArr = pathTemp.split("::");
  const slicedArr = splitedArr.slice(0, 2 * (nestNum + 1));
  const currPath = slicedArr.join("::");

  //* Constraints
  const constraintValues = getConstraintValues(constraints, schema);
  // Para ocultar el input en caso de que las contstraints sean todas las opciones disponibles
  const [hideInputByContraints, setHideInputByContraints] = useState(false); 
  const [currentConstraints, setCurrentConstraints] = useState(null);

  //* Modales
  const [showEditLabelModal, setShowEditLabelModal] = useState(false);
  const [showModalAddChild, setShowModalAddChild] = useState(false);
  const [showModalLocales, setShowModalLocales] = useState(false);
  const [showExampleModal, setShowExampleModal] = useState(false);

  //* States para el funcionamiento
  const [selectedKey, setSelectedKey] = useState(scope.selected || "");
  const [showLabel, setShowLabel] = useState(false);
  const [itemExamples, setitemExamples] = useState([]);
  const [rerenderItself, setRerenderItself] = useState(false);
  const [rerenderChild, setRerenderChild] = useState(false);
  const [required, setRequired] = useState(schema.required || false);

  //* Manejo de los catalogos
  const showErrorLastChild = scope.selected ? false : true;
  const isLastChild = scope.lastChild;
  const styleDark = gTheme === "light" ? "" : "dark-mode";

  //* keys que incluye cada catalogo
  const type = schema.type;
  const grid = schema.grid;
  const sensitive = schema.sensitive;
  const catalogue = schema.catalogue;
  const isOwn = schema.isOwn;
  const hidden = schema.hidden;
  const conditionals = "conditionals" in schema;
  const hasCatConstraints = "otherCatConstraints" in schema;

  //* Resumen de parametros para enviar en el manejo de el schema y la data
  const utilHdlSch = { entireSchema, setSchemaState, path, pathSchema };
  const utilHdlChg = { origin, entireFormData, setFormData };
  const utilConstraints = { origin, constraints, setConstraints };
  const params = {
    catalogue,
    sensitive,
    isOwn,
    origin,
    ...(conditionals && { conditionals }),
  };

  // SHOW LABEL #QUERYS
  useEffect(() => {
    if (!isForLanding && !labelShowed) {
      setShowLabel(true);
      return;
    }
    if (!labelShowed && hidden) {
      const especialFound = especialCatalogues.find(item=>item.catalogue==catalogue  && item.isOwn==isOwn && item.nestNum==nestNum);
      const childIndex = Array.isArray(scope.children) && scope.children.findIndex(item=>item.key===search[especialFound?.query]);
      if (childIndex < 0) {
        setShowLabel(true);
      }
    } else if (!labelShowed) {
      setShowLabel(true);
    }
  }, []);

  useEffect(() => {
    setRerenderChild(true);
  }, [selectedKey]);

  useEffect(() => {
    if (rerenderChild) {
      setRerenderChild(false);
    }
  }, [rerenderChild]);

  useEffect(() => {
    if (rerenderItself) {
      setRerenderItself(false);
    }
  }, [rerenderItself]);

  useEffect(() => {
    if (!scope.selected) {
      handleQuerys();
    }
    setSelectedKey(scope.selected);
  }, [scope]);

  useEffect(() => { // CONSTRAINTS
    if (!_.isEqual(_.sortBy(constraintValues), _.sortBy(currentConstraints))) {
      setCurrentConstraints(constraintValues);
    }
  }, [constraints]);

  useEffect(() => { // CONSTRAINTS
    if (currentConstraints) {
      if (currentConstraints.includes(currPath)) {
        hdlSchm({ action: "nullSelection", ...utilHdlSch });
        handleDataNull();
        handleValidate(false);
        setRerenderItself(true);
      }
    } else {
      setCurrentConstraints(constraintValues);
    }
  }, [currentConstraints]);

  useEffect(() => { // CONSTRAINTS
    const childrenKeys = scope?.children?.map(item=>item.path_locales);
    if (_.isEqual(_.sortBy(constraintValues), _.sortBy(childrenKeys))) {
      setHideInputByContraints(true);
      if (schema?.required) {
        hdlSchm({ action: "nullSelection", ...utilHdlSch });
        handleDataNull();
        handleValidate(true);
      };
    } else {
      setHideInputByContraints(false);
      if (schema?.required) {
        handleValidate(false);
      };
    }
  }, [currentConstraints]);

  // Se ejecuta cada que cambia el valor de algun input de tipo catalogo
  const handleKeyChange = (newKey) => {
    if (newKey == selectedKey) return;
    if (!newKey) {
      // Cuando la llave cambia a un string vacio o cuando se crea el componente
      if (scope.selected !== newKey) {
        // Cuando el selectedKey tiene valor y cambia a "" -- Esto es para que se seleccione un elemento pot defecto
        hdlSchm({ action: "nullSelection", ...utilHdlSch });
        handleDataNull();
        handleValidate(false);
      }
    } else if (scope.selected !== newKey) {
      // Cuando la selectedKey es diferente a la seleccionada en el schema -- Esto es para que se seleccione un elemento pot defecto
      const actualScope = scope.children.find((obj) => obj.key === newKey);
      // if (isUniqueSelectionDefault) {
      //   if (Array.isArray(scope.children[0].childs)) {
      //     if (!scope.next && !scope.selected) { // Esto es para que se seleccione un elemento pot defecto
      //       hdlSchm({ action: "selection", children: scope.children[0].childs, selected: scope.children[0].key, ...utilHdlSch });
      //       handleValidate(false);
      //     }
      //   } else { // Cuando el ultimo hijo es unico
      //     hdlSchm({ action: "noNextPath", selected: newKey, ...utilHdlSch });
      //     handleValidate(true);
      //     handleDataNormal(newKey);
      //   }
      // } else {
      const pathOnBack = actualScope?.path;
      if (pathOnBack) {
        catalogByPart({
          is_own: isOwn,
          catalogue,
          path: pathOnBack,
          ...(forEdition && { show_all: true }),
        })
          .then((resp) => {
            if (resp?.data?.length === 0) {
              hdlSchm({
                action: "noNextPath",
                selected: newKey,
                ...utilHdlSch,
              });
              handleValidate(true);
              handleDataNormal(newKey);
            } else {
              hdlSchm({
                action: "selection",
                children: resp.data,
                selected: newKey,
                ...utilHdlSch,
              });
              handleValidate(false);
              handleDataEndpoint({ pathOnBack, scope: resp.data });
            }
          })
          .catch(console.log);
      } else {
        hdlSchm({ action: "noNextPath", selected: newKey, ...utilHdlSch });
        handleValidate(true);
        handleDataNormal(newKey);
      }
      // }
    }
  };

  // Manejo de la data cuando esta tiene un path en el schema para obtener mas hijos "selection"
  const handleDataEndpoint = ({ pathOnBack, scope, acumulatedPath }) => {
    // if (scope.length === 1) {  // una sola opcion
    //   if (Array.isArray(scope[0].childs) || Array.isArray(scope.children)) { // una sola opcion y tiene hijo o hijos
    //     handleDataEndpoint({
    //       scope: scope[0].childs,
    //       acumulatedPath: scope[0].path
    //     })
    //   } else {  // una sola opcion y no tiene hijos
    //     if (pathOnBack) {
    //       hdlChg({ e: { target: { name: catalogue, value: `${pathOnBack}::type::${scope[0].key}` } }, entirePathData: pathData, params, ...utilHdlChg });
    //     } else {
    //       hdlChg({ e: { target: { name: catalogue, value: `${acumulatedPath}::type::${scope[0].key}` } }, entirePathData: pathData, params, ...utilHdlChg });
    //     }
    //   }
    // } else { // varias opciones
    if (pathOnBack) {
      hdlChg({
        e: { target: { name: catalogue, value: pathOnBack } },
        entirePathData: pathData,
        params,
        ...utilHdlChg,
      });
      if (hasCatConstraints)
        hdlConstraints({ value: pathOnBack, ...utilConstraints });
    } else {
      hdlChg({
        e: { target: { name: catalogue, value: acumulatedPath } },
        entirePathData: pathData,
        params,
        ...utilHdlChg,
      });
      if (hasCatConstraints)
        hdlConstraints({ value: acumulatedPath, ...utilConstraints });
    }
    // }
  };

  // Por el momento se utiliza solo en el caso donde ya no hay mas hijos para seguir, "noNextPath"
  const handleDataNormal = (newKey) => {
    const valueTemp = value ? value : "";
    const splitedArr = valueTemp.split("::");
    const slicedArr = splitedArr.slice(0, 2 * nestNum);
    const joined = slicedArr.join("::");
    if (joined) {
      hdlChg({
        e: { target: { name: catalogue, value: `${joined}::type::${newKey}` } },
        entirePathData: pathData,
        params,
        ...utilHdlChg,
      });
      if (hasCatConstraints)
        hdlConstraints({
          value: `${joined}::type::${newKey}`,
          ...utilConstraints,
        });
    } else {
      hdlChg({
        e: { target: { name: catalogue, value: `type::${newKey}` } },
        entirePathData: pathData,
        params,
        ...utilHdlChg,
      });
      if (hasCatConstraints)
        hdlConstraints({ value: `type::${newKey}`, ...utilConstraints });
    }
  };

  // Para cuando la seleccion se borra "nullSelection"
  const handleDataNull = () => {
    const valueTemp = value ? value : "";
    const splitedArr = valueTemp.split("::");
    const slicedArr = splitedArr.slice(0, 2 * nestNum);
    const joined = slicedArr.join("::");
    if (joined) {
      hdlChg({
        e: { target: { name: catalogue, value: `${joined}` } },
        entirePathData: pathData,
        params,
        ...utilHdlChg,
      });
      if (hasCatConstraints)
        hdlConstraints({ value: `${joined}`, ...utilConstraints });
    } else {
      hdlChg({
        e: { target: { name: catalogue, value: `` } },
        entirePathData: pathData,
        params,
        ...utilHdlChg,
      });
      if (hasCatConstraints) hdlConstraints({ value: ``, ...utilConstraints });
    }
  };

  // Esto es para cuando se quiere que se elija sola la unidad de negocio // QUERYS
  const handleQuerys = () => {
    const especialFound = especialCatalogues.find(item=>item.catalogue==catalogue && item.isOwn==isOwn && item.nestNum==nestNum)
    if (especialFound) {
      const childIndex = Array.isArray(scope.children) && scope.children.findIndex(item=>item.key===search[especialFound.query])
      if (childIndex >= 0) {
        if (Array.isArray(scope.children[childIndex].childs)) {
          hdlSchm({ action: "selection", children: scope.children[childIndex].childs, selected: scope.children[childIndex].key, ...utilHdlSch });
          handleValidate(false);
        } else {
          hdlSchm({ action: "noNextPath", selected: scope.children[childIndex].key, ...utilHdlSch });
          handleValidate(true);
        }
        setSelectedKey(scope.children[childIndex].key);
        handleKeyChange(scope.children[childIndex].key);
        // Data
        const valueTemp = value ? value : "";
        const splitedArr = valueTemp.split("::");
        const slicedArr = splitedArr.slice(0, ((2 * (nestNum))));
        const joined = slicedArr.join("::");
        if (joined) {
          hdlChg({e:{target:{name: catalogue, value: `${joined}::type::${scope.children[0].key}`}}, entirePathData: pathData, params, ...utilHdlChg});
        } else {
          hdlChg({e:{target:{name: catalogue, value: `type::${scope.children[0].key}`}}, entirePathData: pathData, params, ...utilHdlChg});
        }
      }
    }
  }

  // Retorna las opciones de los catalogos ordenadas y filtradas por las constraints
  const orderChildren = (children) => {
    const orderedChildren = children.sort((a, b) => a.label - b.label);
    const orderOther = orderedChildren.sort((a, b) => {
      if (
        a.label.toLowerCase() === "other" ||
        a.label.toLowerCase() === "otro"
      ) {
        return 1; // si a es "other", lo coloca al final
      } else if (
        b.label.toLowerCase() === "other" ||
        b.label.toLowerCase() === "otro"
      ) {
        return -1; // si b es "other", lo coloca al final
      } else {
        return 0; // si a y b son iguales, no cambia el orden
      }
    });
    // const finalOrder = orderOther.filter(child => !thisConstraints.includes(child.path_locales))
    const finalOrder = orderOther.filter((child) => {
      for (const constraint of constraintValues) {
        if (child.path_locales.startsWith(constraint)) {
          return false;
        }
      }
      return true;
    });
    return finalOrder;
  };

  // Devuelve las opciones de que va a tener el input dependiendo de si esta activo o no
  const getOptions = () => {
    var options = [];
    const childrens = orderChildren(scope.children);

    for (var i = 0; i < childrens.length; i++) {
      if (childrens[i].is_active === true) {
        options.push({
          value: childrens[i].key,
          name: childrens[i].path,
          // label: childrens[i]?.locales[t("lang")] ? childrens[i]?.locales[t("lang")] : childrens[i].label,
          // label: childrens[i]?.locales[lang] ? childrens[i].locales[lang] : childrens[i].label,
          label: childrens[i]?.locales?.lang ? childrens[i]?.locales.lang : childrens[i].label,
          description: childrens[i].description,
          examples: childrens[i].examples,
          container_name: childrens[i].container_name,
          locales: childrens[i].locales,
          is_active: true,
        });
      } else if (childrens[i].is_active === false && forEdition) {
        options.push({
          value: childrens[i].key,
          name: childrens[i].path,
          label: childrens[i]?.locales?.lang ? childrens[i]?.locales.lang : childrens[i].label,
          description: childrens[i].description,
          examples: childrens[i].examples,
          container_name: childrens[i].container_name,
          locales: childrens[i].locales,
          is_active: false,
        });
      }
    }

    return options;
  };

  // Agrega la clase selected en caso de que la opcion se la seleccionada
  const isSelected = (condition) => {
    return condition ? "selected" : "";
  };

  // Controla los ejemplos de los inputs que los contengan y muestra el modal
  const handleSetExamples = (examplesOfItem, event) => {
    event.stopPropagation();
    setitemExamples(examplesOfItem);
    setShowExampleModal(true);
  };

  // Obtiene la desctipcion para colocarla en ls inputs y un boton para manejar el modal de ejemplos
  const GetDescritionAndExamples = ({ item }) => {
    const description = item.description ? item.description : null;
    const examples = item.examples ? item.examples : null;

    return (
      <>
        {description && typeof description === "string" && (
          <p className={`${styleDark} ${isSelected(selectedKey === item.key)}`}>
            {description}
          </p>
        )}
        {examples && Array.isArray(examples) && (
          <MCButton
          label={i18n["catalogues_examples_title"]}
            variant="primary"
            outline
            size="sm"
            onClick={(e) => handleSetExamples(examples, e)}
          />
        )}
      </>
    );
  };

  // Como se ve la opcion de los inputs tipo select
  const Option = ({ ...props }) => (
    <components.Option {...props}>
      <div>
        <div>
          {props.data.label}{" "}
          {props.data.is_active === false &&
            `(${i18n["catalogues_inactive"]})`}
        </div>
        <GetDescritionAndExamples item={props.data} />
      </div>
    </components.Option>
  );

  // Como se ve la opcion seleccionada de los inputs tipo select
  const IconSingleValue = (props) => (
    <components.SingleValue {...props}>
      {props.data.label}{" "}
      {props.data.is_active === false && `(${i18n["catalogues_inactive"]})`}
    </components.SingleValue>
  );

  // (Edicion) Sirve para que cuando se este editando un catalogo y se quiera habilitar o inhabilitar una opcion
  const handleEnableDisableOption = async () => {
    try {
      const childToChange = getOptions().find(
        (item) => item.value == selectedKey
      );
      const currentActive = childToChange.is_active;
      updateStatusCatalogue({
        is_own: isOwn,
        catalogue,
        path: currPath,
        is_active: !currentActive,
      });
      rerenderParent();
    } catch (error) {
      console.log(error);
    }
  };

  // En caso de que sea un catalogo especial y este se autocomplete con querys // QUERYS
  const handleHidden = () => {
    if (!isForLanding) return false;
    const especialFound = especialCatalogues.find(item=>item.catalogue==catalogue && item.isOwn==isOwn && item.nestNum==nestNum);
    if (especialFound) {
      const childIndex = Array.isArray(scope.children) && scope.children.findIndex(item=>item.key===search[especialFound.query])
      if (childIndex>=0 && hidden) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // Catalogo de tipo Select
  if (
    type === INPUT_TYPE_CATALOGUE_SELECT ||
    type === INPUT_TYPE_CATALOGUE_SELECT_CONDITIONAL
  ) {
    return (
      <>
        {forEdition && showEditLabelModal && (
          <ModalEditInfoCatalogue
            objSelection={getOptions().find(
              (item) => item.value === selectedKey
            )}
            catalogue={catalogue}
            is_own={isOwn}
            onHide={() => setShowEditLabelModal(false)}
            path={value}
            nestNum={nestNum}
            rerenderParent={rerenderParent}
          />
        )}
        {forEdition && showModalLocales && (
          <ModalLocales
            objSelection={getOptions().find(
              (item) => item.value === selectedKey
            )}
            catalogue={catalogue}
            is_own={isOwn}
            onHide={() => setShowModalLocales(false)}
            path={value}
            nestNum={nestNum}
            rerenderParent={rerenderParent}
          />
        )}
        {forEdition && showModalAddChild && (
          <ModalAddChild
            path={currPath}
            catalogue={catalogue}
            is_own={isOwn}
            onHide={() => setShowModalAddChild(false)}
            rerenderParent={rerenderParent}
          />
        )}
        {showExampleModal && (
          <ModalExamples
            itemExamples={itemExamples}
            setShowExampleModal={setShowExampleModal}
            styleDark={styleDark}
          />
        )}
        { !handleHidden() && !hideInputByContraints && <Col lg={schema.grid || 12} className={`preview-input-container`}>
          { showLabel && (
            <div className="d-flex">
              <MCLabel
                text={`${schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:${schema.required ? " *" : ""}`}
                className={`${schema.required ? "label-required" : ""}`}
              />
              {
                hidden && !isForLanding &&
                <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
              }
            </div>
          )}{" "}
          { containerName && <MCLabel text={containerName} /> }
          <div className={`preview-input-container-inp`}>
            {scope.children && !rerenderItself && (
              <div className={`${showErrorLastChild ? returnValidClass("container") : ""} ${forEdition ? "d-flex" : ""}`}>
                <div className="catalog-for-edition">
                  <MCSelect
                    menuPortalTarget={document.body}
                    placeholder={<div>{i18n["catalogues_placeholder"]}</div>}
                    options={getOptions(scope.children)}
                    onChange={(sel) => handleKeyChange(sel.value)}
                    components={{ Option, SingleValue: IconSingleValue }}
                    value={getOptions().find(
                      (item) => item.value == selectedKey
                    )}
                  />
                </div>
                {selectedKey && forEdition && (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle variant="">
                        <Icon name="more_dots" className="dyIcon2" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => setShowEditLabelModal(true)}
                        >
                          <Icon name="setting" />
                          <span className="ms-2">
                            {i18n["catalogues_edition_configuration"]}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setShowModalLocales(true)}
                        >
                          <Icon name="lenguage_square" />
                          <span className="ms-2">
                            {i18n["catalogues_edition_languages"]}
                          </span>
                        </Dropdown.Item>
                        {nestNum < 3 && (
                          <Dropdown.Item
                            onClick={() => setShowModalAddChild(true)}
                          >
                            <Icon name="outline_edit_2" />
                            <span className="ms-2">
                              {i18n["catalogues_edition_add_child"]}
                            </span>
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={handleEnableDisableOption}>
                          <Icon name="trash_icon" />
                          <span className="ms-2">
                            {getOptions().find(
                              (item) => item.value == selectedKey
                            )?.is_active
                              ? i18n["catalogues_edition_disable"]
                              : i18n["catalogues_edition_enable"]}
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
              </div>
            )}
          </div>
          {isLastChild && (
            <ReturnErrorMesages tryNext={tryToNext} valid={valid} />
          )}
        </Col> }
        {scope?.next && selectedKey && !rerenderChild && (
          <CatalogueInput
            containerName={
              !selectedKey
                ? null
                : scope.children.find((item) => item.key === selectedKey)
                    ?.container_name || null
            }
            schema={schema}
            scope={scope.next}
            value={value}
            valid={valid}
            origin={origin}
            path={`${path}next.`}
            labelShowed={ labelShowed ? true : showLabel}
            pathSchema={pathSchema}
            pathData={pathData}
            nestNum={nestNum + 1}
            handleValidate={handleValidate}
            returnValidClass={returnValidClass}
            tryToNext={tryToNext}
            entireSchema={entireSchema}
            entireFormData={entireFormData}
            setSchemaState={setSchemaState}
            setFormData={setFormData}
            constraints={constraints}
            setConstraints={setConstraints}
            rerenderParent={rerenderParent}
            forEdition={forEdition}
          />
        )}
      </>
    );
  }
  // Catalogo de tipo Radio
  if (
    type === INPUT_TYPE_CATALOGUE_RADIO ||
    type === INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION ||
    type === INPUT_TYPE_CATALOGUE_RADIO_CONDITIONAL ||
    type === INPUT_TYPE_CATALOGUE_RADIO_DESCRIPTION_CONDITIONAL
  ) {
    return (
      <>
        {showExampleModal && (
          <ModalExamples
            itemExamples={itemExamples}
            setShowExampleModal={setShowExampleModal}
            styleDark={styleDark}
          />
        )}
        { !handleHidden() && !hideInputByContraints && <Col lg={schema.grid || 12} className={`preview-input-container ${ showErrorLastChild ? returnValidClass("container") : ""}`}>
          {/* ${schema.type.includes(INPUT_TYPE_CATALOGUE_SELECT)?"m-0":""} */}
          { showLabel && (
            <div className="d-flex">
              <MCLabel
                text={`${schema.locales?.[lang] === undefined ? schema.label : schema.locales[lang]}:${schema.required ? " *" : ""}`}
                className={`${schema.required ? "label-required" : ""}`}
              />
              {
                hidden && !isForLanding &&
                <Icon className="ms-2 fill-yellow" name="outline_eye_slash" />
              }
            </div>
          )}{" "}
          {containerName ? (
            <MCLabel text={containerName} />
          ) : (
            nestNum !== 0 && <div style={{ height: 32 }}></div>
          )}
          <div
            className={`preview-input-container-inp no-mt-auto ${
              schema.type.includes(INPUT_TYPE_CATALOGUE_RADIO) && grid !== 12
                ? "dyBorder1 rounded"
                : ""
            }`}
          >
            <CatalogueRadioInput
              scope={scope}
              pathData={pathData}
              type={type}
              nestNum={nestNum}
              grid={grid}
              returnValidClass={returnValidClass}
              showErrorLastChild={showErrorLastChild}
              GetDescritionAndExamples={GetDescritionAndExamples}
              handleKeyChange={handleKeyChange}
              selectedKey={selectedKey}
              isSelected={isSelected}
              orderChildren={orderChildren}
              styleDark={styleDark}
            />
          </div>
          {!isLastChild && grid === 12 && <hr className="my-2" />}
          {isLastChild && (
            <ReturnErrorMesages tryNext={tryToNext} valid={valid} />
          )}
        </Col>}
        {scope?.next && selectedKey && !rerenderChild && (
          <CatalogueInput
            containerName={
              !selectedKey
                ? null
                : scope.children.find((item) => item.key === selectedKey)
                    ?.container_name || null
            }
            schema={schema}
            scope={scope.next}
            value={value}
            valid={valid}
            origin={origin}
            path={`${path}next.`}
            labelShowed={ labelShowed ? true : showLabel}
            pathSchema={pathSchema}
            pathData={pathData}
            nestNum={nestNum + 1}
            handleValidate={handleValidate}
            returnValidClass={returnValidClass}
            tryToNext={tryToNext}
            entireSchema={entireSchema}
            entireFormData={entireFormData}
            setSchemaState={setSchemaState}
            setFormData={setFormData}
            constraints={constraints}
            setConstraints={setConstraints}
          />
        )}
      </>
    );
  }
  return <></>;
};
